<template>
  <div class="flex flex-col h-[calc(100vh-10rem)]">
    <!-- Request content -->
    <div class="flex-grow overflow-scroll">
      <support-request-show
        v-if="supportRequest && !loading"
        :resource="supportRequest"
        :cancel-callback="handleCancel"
      />

      <support-request-show-empty
        v-else-if="loading"
      />
    </div>

    <!-- Action buttons -->
    <action-buttons
      v-if="supportRequest && !loading"
      class="mt-4 content-end"
      :resource="supportRequest"
      :cancel-callback="handleCancel"
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import {
  cancelSupportRequest,
  fetchSupportRequest as fetchApiSupportRequest,
} from '@shared/http/api'
import ActionButtons from '@app/components/resources/support_request/ActionButtons.vue'
import SupportRequestShow from '@app/components/resources/support_request/SupportRequestShow.vue'
import SupportRequestShowEmpty from '@app/components/resources/support_request/SupportRequestShowEmpty.vue'

const route = useRoute()
const router = useRouter()
const store = useStore()
const { t } = useI18n()

const supportRequest = ref(null)
const loading = ref(false)

function fetchSupportRequest() {
  return new Promise((resolve) => {
    const { id } = route.params
    loading.value = true

    fetchApiSupportRequest(id)
      .then((response) => {
        supportRequest.value = response.data.data
      })
      .finally(() => {
        loading.value = false
        resolve()
      })
  })
}

function handleCancel() {
  cancelSupportRequest(supportRequest.value.id)
    .then(() => {
      router.push({
        name: 'index-support-requests',
      })

      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('ttmt.support_requests.cancel.success'),
          type: 'success',
        },
      )
    })
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchSupportRequest()
})
</script>
