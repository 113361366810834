<template>
  <div>
    <div
      v-if="resource.authorizations.cancel"
      class="flex justify-center pb-2"
    >
      <app-button
        feature="cancel"
        class="w-5/6"
        :label="t('ttmt.common.actions.cancel')"
        @click="handleCancel"
      />
    </div>

    <div
      v-if="canAddMessage"
      class="flex justify-center pb-2"
    >
      <app-button
        feature="create"
        class="w-5/6"
        :label="t('ttmt.common.actions.add_message')"
        @click="router.push({ name: 'create-support-messages' })"
      />
    </div>

    <!-- Modal -->
    <app-overlay
      v-if="resource.authorizations.cancel"
      :show="cancelConfirmModalDisplayed"
      @clicked="handleCancelCancel"
    >
      <app-modal @closed="handleCancelCancel">
        <app-confirm
          @cancelled="handleCancelCancel"
          @confirmed="handleCancelConfirm"
        >
          <p>
            {{ t('ttmt.support_requests.cancel.confirm', { support_request_title: resource.attributes.title }) }}
          </p>
        </app-confirm>
      </app-modal>
    </app-overlay>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import AppButton from '@shared/components/ui/AppButton.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import useSupportRequest from '@shared/hooks/resources/supportRequest'

const { t } = useI18n()
const router = useRouter()

const props = defineProps({
  // Support request API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Cancel the support request
  cancelCallback: {
    type: Function,
    default: () => ({}),
  },
})

const emits = defineEmits(['canceled'])

const {
  canAddMessage,
  handleCancel,
  cancelConfirmModalDisplayed,
  handleCancelCancel,
  handleCancelConfirm,
} = useSupportRequest(props, emits)
</script>
